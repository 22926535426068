<template>
  <div class="sidebarNew bg-white">
    <div class="sidebar">
      <div>
        <ul class="nav-list navbar-nav h-100">
          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/dashboard" @click="toggleSidebar">
              <img src="../assets/images/icon/grid.svg" alt="nav icon" class="default img-fluid" width="24" />
              Dashboard

            </router-link>
          </li>



          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/withdraw-history" @click="toggleSidebar">
              <img src="../assets/images/icon/grid.svg" alt="nav icon" class="default img-fluid" width="24" />
              Withdraw History
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/deposit-history" @click="toggleSidebar">
              <img src="../assets/images/icon/grid.svg" alt="nav icon" class="default img-fluid" width="24" />
              Deposit History
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/user-history" @click="toggleSidebar">
              <img src="../assets/images/icon/grid.svg" alt="nav icon" class="default img-fluid" width="24" />
              Users History
            </router-link>
          </li>

          <li class="nav-item">
            <div class="accordion custom_accord" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button collapsed px-4 py__10 gap-2" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    <img src="../assets/images/icon/nav-icon-4.svg" alt="nav icon" class="default img-fluid"
                      width="24" />
                    Fund Transfer
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse  ps-3" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/fund-history" @click="toggleSidebar">
              <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
              Fund Transfer History
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/fund-transfer" @click="toggleSidebar">
              <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
              Fund Transfer By Admin
            </router-link>
          </li>


      </div>
    </div>
  </div>
  </li>
  <li class="nav-item">
    <div class="accordion custom_accord" id="accordionExample2">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button collapsed px-4 py__10 gap-2" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
            Topup
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse  ps-3" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample2">

  <li class="nav-item">
    <router-link class="nav-link d-flex align-items-center gap-2" to="/topup-history" @click="toggleSidebar">
      <img src="../assets/images/icon/grid.svg" alt="nav icon" class="default img-fluid" width="24" />
      Topup History
    </router-link>
  </li>
  </div>
  </div>
  </div>
  </li>

  <li class="nav-item">
    <div class="accordion custom_accord" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button collapsed px-4 py__10 gap-2" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseOne">
            <img src="../assets/images/icon/nav-icon-4.svg" alt="nav icon" class="default img-fluid" width="24" />
            Income Report
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse  ps-3" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
  <li class="nav-item">
    <router-link class="nav-link d-flex align-items-center gap-2" to="/fund-history" @click="toggleSidebar">
      <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
      Level Income
    </router-link>
  </li>

  <li class="nav-item">
    <router-link class="nav-link d-flex align-items-center gap-2" to="/fund-transfer" @click="toggleSidebar">
      <img src="../assets/images/icon/roiIncome.svg" alt="nav icon" class="default img-fluid" width="24" />
      ROI On ROI Income
    </router-link>
  </li>


  <li class="nav-item">
    <router-link class="nav-link d-flex align-items-center gap-2" to="/roi-income" @click="toggleSidebar">
      <img src="../assets/images/icon/roi.svg" alt="nav icon" class="default img-fluid" width="24" />
      ROI Income
    </router-link>
  </li>

  <li class="nav-item">
    <router-link class="nav-link d-flex align-items-center gap-2" to="/royalty-income" @click="toggleSidebar">
      <img src="../assets/images/icon/royalty.svg" alt="nav icon" class="default img-fluid" width="24" />
      Royalty Income
    </router-link>
  </li>
  <li class="nav-item">
    <router-link class="nav-link d-flex align-items-center gap-2" to="/infinity-bonus" @click="toggleSidebar">
      <img src="../assets/images/icon/infinity.svg" alt="nav icon" class="default img-fluid" width="24" />
      Infinity Bonus
    </router-link>
  </li>
  <li class="nav-item">
    <router-link class="nav-link d-flex align-items-center gap-2" to="/mid-month-bonus" @click="toggleSidebar">
      <img src="../assets/images/icon/midmonth.svg" alt="nav icon" class="default img-fluid" width="24" />
      Mid Month Bonus
    </router-link>
  </li>
  <li class="nav-item">
    <router-link class="nav-link d-flex align-items-center gap-2" to="/booster-bonus" @click="toggleSidebar">
      <img src="../assets/images/icon/booster.svg" alt="nav icon" class="default img-fluid" width="24" />
      Booster Bonus
    </router-link>
  </li>
  </div>
  </div>
  </div>
  </li>

  <li class="nav-item">
    <router-link class="nav-link d-flex align-items-center gap-2" to="/profile" @click="toggleSidebar">
      <img src="../assets/images/icon/user.svg" alt="nav icon" class="default img-fluid" width="24" />
      Profile
    </router-link>
  </li>

  <li class="nav-item">
    <router-link class="nav-link d-flex align-items-center gap-2" to="/security" @click="toggleSidebar">
      <img src="../assets/images/icon/shield-check.svg" alt="nav icon" class="default img-fluid" width="24" />
      Security
    </router-link>
  </li>


  <li class="nav-item">
    <a class="nav-link d-flex align-items-center gap-2" href="javascript:void(0);">
      <img src="../assets/images/icon/page-icon-11.svg" alt="nav icon" class="default img-fluid" width="24" />
      <span class="mb-0" data-bs-toggle="modal" data-bs-target="#logout_modal" data-dismiss="modal">Logout</span>
    </a>
  </li>


  
        <li class="nav-item">
          <div class="accordion custom_accord" id="accordionExampleSupport">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneSupport">
                <button class="accordion-button collapsed px-4 py__10 gap-2" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThreeSupport" aria-expanded="false" aria-controls="collapseOne">
                  <img src="../assets/images/icon/nav-icon-4.svg" alt="nav icon" class="default img-fluid" width="24" />
                  Tickets
                </button>
              </h2>
              <div id="collapseThreeSupport" class="accordion-collapse collapse  ps-3" aria-labelledby="headingOneSupport"
                data-bs-parent="#accordionExampleSupport">
        <li class="nav-item">
          <router-link class="nav-link d-flex align-items-center gap-2" to="/tickets/1" @click="toggleSidebar">
            <!-- <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" /> -->
            Open Tickets
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link d-flex align-items-center gap-2" to="/tickets/2" @click="toggleSidebar">
            <!-- <img src="../assets/images/icon/roiIncome.svg" alt="nav icon" class="default img-fluid" width="24" /> -->
            In-Progress Tickets
          </router-link>
        </li>


        <li class="nav-item">
          <router-link class="nav-link d-flex align-items-center gap-2" to="/tickets/3" @click="toggleSidebar">
            <!-- <img src="../assets/images/icon/roi.svg" alt="nav icon" class="default img-fluid" width="24" /> -->
            On Hold Tickets
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link d-flex align-items-center gap-2" to="/tickets/4" @click="toggleSidebar">
            <!-- <img src="../assets/images/icon/royalty.svg" alt="nav icon" class="default img-fluid" width="24" /> -->
            Completed Tickets
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link d-flex align-items-center gap-2" to="/tickets/5" @click="toggleSidebar">
            <!-- <img src="../assets/images/icon/royalty.svg" alt="nav icon" class="default img-fluid" width="24" /> -->
            Rejected Tickets
          </router-link>
        </li>
       
        </div>
        </div>
        </div>
        </li>
  </ul>
  </div>
  </div>

  <div class="modal fade" id="logout_modal" tabindex="-1" aria-labelledby="logout_modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 p-0">
          <h5 class="modal-title"></h5>
          <button type="button" class="btn-close mt-2 mx-2" id="closelogoutmodal" data-bs-dismiss="modal"
            aria-label="Close"></button>

          <!-- <div class="close_box ">
                            <button type="button" id="closelogoutmodal" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> -->
        </div>
        <div class="modal-body">
          <div class="logout_main py-4 text-center">
            <div class="img_log d-flex align-items-center justify-content-center mb-4 m-auto">
              <h2 class="text-danger">!</h2>
            </div>
            <div class="form_box mb-4">
              <h3>Please Confirm</h3>
              <p>Are you sure you want to logout?</p>
            </div>
            <div class="form_box mb-4">
              <div class="button-box" v-if="loading">
                <button type="button" class="btn btn-primary">
                  Loading
                </button>
              </div>
              <div class="button-box dd" v-else>
                <button type="button" class="btn btn-primary mx-2" @click="doLogout()">Logout </button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


  </div>
</template>

<script>
import ApiClass from '@/api/api';


export default {
  name: "SidebarComponent",

  data() {
    return {
      // SidebarIcon,
      showSidebar: true
    };
  },
  methods: {
    toggleSidebar() {
      if (window.innerWidth < 767) {
        const element = document.getElementById('cc-side');
        element.classList.add('collapsed-sidebar');
      }
    },
    async doLogout() {
      this.loading = true;
      let response = await ApiClass.deleteRequest('user/logout', true);

      if (response?.data) {
        this.loading = false;
        if (response.data.status_code == 0) {
          this.failed(response.data.message)
          return
        }
        if (response.data.status_code == 1) {
          this.success(response.data.message)
          document.getElementById("closelogoutmodal").click()
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          this.$store.commit("CLEAR_STORE", "");
          return this.$router.push("/login");
        }
      }
    },
  }
};
</script>

<style scoped>
.sidebarNew {
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sidebarNew ul {
  list-style: none;
}

.sidebarNew ul a {
  padding: 10px 24px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
}

.sidebarNew ul a:hover {
  padding: 10px 24px;
  background-color: var(--blueN);
  color: #FFF;
}

.sidebarNew ul a:hover img {
  filter: invert(100%) sepia(99%) saturate(2%) hue-rotate(12deg) brightness(112%) contrast(101%);
}

.dd {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.custom_accord .accordion-button:focus {
  border-color: #ffffff;
  box-shadow: none;
}

.custom_accord .accordion-button:not(.collapsed) {
  background: #FFF;
  box-shadow: none;
}

.custom_accord .accordion-item {
  border: none;
}

.py__10 {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
</style>