import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/auth/LoginView.vue";

const routes = [
  {
    path: "/", 
    alias: ['/login'],
    name: "login", 
    component: LoginView,
    meta: { authOnly: false },
  },

  {
    path: "/otp",
    name: "otp",
    component: () =>
      import(/* webpackChunkName: "otp" */ "../views/auth/OTPVerification.vue"),
    meta: { authOnly: false },
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/DashboardView.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/withdraw-history",
    name: "WithdrawHistory",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/WithdrawHistory.vue"
      ),
    meta: { authOnly: true },
  },

  {
    path: "/deposit-history",
    name: "DepositHistory",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/DepositHistory.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/user-history",
    name: "UserHistory",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/UserHistory.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/fund-history",
    name: "FundTransferHistory",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/FundTransferHistory.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/roi-income",
    name: "RoiIncome",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/IncomeReport/RoiIncomeView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/royalty-income",
    name: "RoyaltyIncome",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/IncomeReport/RoyaltyView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/infinity-bonus",
    name: "InfinityBonus",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/IncomeReport/InfinityReportView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/mid-month-bonus",
    name: "MidMonthPool",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/IncomeReport/MidMonthPool.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/booster-bonus",
    name: "BoosterBonus",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/IncomeReport/BoosterBonus.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/security",
    name: "security",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/SecurityView.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/ProfileView.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/members/:member?",
    name: "members",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/MembersView.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/level-members",
    name: "LevelMembers",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/members/LevelMembers.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/direct-members",
    name: "DirectMembers",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/members/DirectMembers.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/generation",
    name: "GenerationTree",
    component: () =>
      import(
        /* webpackChunkName: "terminate" */ "../views/members/GenerationTree.vue"
      ),
    meta: { authOnly: true },
  },

  // NEW ROUTES

  {
    path: "/fund-transfer",
    name: "FundTransfer",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/FundTransfer.vue"),
    meta: { authOnly: true },
  },

  {
    path: "/topup-history",
    name: "TopUpHistory",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/TopUpHistory.vue"),
    meta: { authOnly: true },
  },
  {
    path: '/tickets/:id',
    name: 'TicketView', 
    component: () => import(/* webpackChunkName: "TicketView" */ '../views/TicketsView.vue'),
    meta: { authOnly: true, submenu: "Submenu" }
  },
  {
    path: '/tickets/detail/:id',
    name: 'TicketDetail',
    component: () => import('../views/TicketDetailView.vue'),
    meta: {
      authOnly: true
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");
  const isAuth = to.matched.some((record) => record.meta.authOnly);

  if (to.fullPath == "/termcondition") {
    return next();
  }

  if (isAuth && !loggedIn) {
    return next({ path: "/login" });
  } else if (!isAuth && loggedIn) {
    return next({ path: "/dashboard" });
  } else if (!isAuth && !loggedIn) {
    return next();
  }
  next();
});

export default router;
